import React from 'react';

class MenuToggle extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    } 

    handleChange(event) {

        if(typeof this.props.menutogglefunction !== "undefined" 
            && this.props.menutogglefunction != null) {
            this.props.menutogglefunction(!event.target.checked);
        } else {
            console.log("Menu Toggle: No callback function defined.");
        }
    }

    render() {
        return(
            <div className="menu-toggle-checkbox">
                <input id="menu-toggle-checkbox" type="checkbox" value={!this.props.menucollapsed} onChange={this.handleChange} tabIndex="1"/>
                <label className="menu-toggle-container" htmlFor="menu-toggle-checkbox" >
                    <span className="bar topbar"></span>
                    <span className="bar bottombar"></span>    
                </label>
            </div>
        );
    }

}

export default MenuToggle;