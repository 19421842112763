import React from 'react';
import Education from '../components/education/education';
import Employment from '../components/employment/employment';
import TextConfig from '../resources/configuration/text-config.json';


class Resume extends React.Component {
    
    populateTechnicalSkills() {
        var technicalJsx = [];
        TextConfig.resume.sections[0].entries.forEach(skill => {
            technicalJsx.push(<p key={"tag-"+skill.replace(" ","-")} className="resume-skill">{skill}</p>)
        });

        return technicalJsx;
    }

    populateWorkExperience() {
        var workJsx = [];
        var index = 0;
        TextConfig.resume.sections[1].entries.forEach(job => {
            workJsx.push(<Employment key={"job"+index} employer={job} />);
            index++;
        });

        return workJsx;
    }
    
    populateEducation() {
        var eduJsx = [];
        var index = 0;
        TextConfig.resume.sections[2].entries.forEach(school => {
                eduJsx.push(<Education key={"institution-"+index} institution={school.institution} degrees={school.degrees} />);
                index++;
        });
        
        return eduJsx;
    }
    
    render() {
        return (
            <div className="container">
                <div className="resume-section">
                    <h3 className="resume-section-header"><i className="material-icons build-icon resume-icon">build</i> Technical Skills</h3>
                    <ul className="techSkillList">
                        {this.populateTechnicalSkills()}
                    </ul>
                </div>
                <div className="resume-section">
                    <h3 className="resume-section-header"><i className="material-icons work-icon resume-icon">work</i> Job Experience</h3>
                    <ul>
                        {this.populateWorkExperience()}
                    </ul>
                </div>
                <div className="resume-section">
                    <h3 className="resume-section-header"><i className="material-icons auto-stories-icon resume-icon">auto_stories</i> Education</h3>
                    <ul>
                        {this.populateEducation()}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Resume;