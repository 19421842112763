import React from 'react';

class AccessibilityMenu extends React.Component {

    constructor(props) {
        super(props);
        this.handleFontChange = this.handleFontChange.bind(this);
    }

    handleFontChange(event) {
        this.props.updatecurrentfont(event.target.value);
    }

    render() {
        return(
            <div className="accessibility-menu">
                <input id="accessibility-checkbox" type="checkbox" />
                <label className="material-icons access-menu-icon" htmlFor="accessibility-checkbox">accessibility_new</label>
                <div className="menu-form-container">
                    <form className="access-menu-form" >
                        <h2 className="header-link">Font Options</h2>
                        
                        <input id="default" type="radio" name="font" value="default" 
                            checked={this.props.currentfont === 'default'}
                            onChange={this.handleFontChange} />
                        <label id="default-label" htmlFor="default">Default</label>
                        <br />
                        
                        <input id="atkinson" type="radio" name="font" value="hyperlegible"
                            checked={this.props.currentfont === 'hyperlegible'}
                            onChange={this.handleFontChange} />
                        <label id="atkinson-label" htmlFor="atkinson">Atkinson HyperLegible</label>
                        <br />
                        
                        <input id="dyslexie" type="radio" name="font" value="opendyslexic"
                            checked={this.props.currentfont === 'opendyslexic'}
                            onChange={this.handleFontChange} />
                        <label id="dyslexie-label" htmlFor="dyslexie">Open Dyslexic</label>
                    </form>
                </div>
            </div>
        );
    }
}

export default AccessibilityMenu;