import React from 'react';

class Employment extends React.Component {

    constructor(props) {
        super(props);

        this.renderJobFunctions = this.renderJobFunctions.bind(this);
    }

    renderJobFunctions() {
        var jobFunctionJsx = [];
        var index = 0;

        this.props.employer.functions.forEach(jobFunction => {
            jobFunctionJsx.push(<li key={"jobFunction"+index} className="job-function">{jobFunction}</li>);
            index++;
        });
        return jobFunctionJsx;
    }

    render() {
        return (
            <div className="employment-container">
                <div className="employer-header">
                    <h3 className="employer-name">{this.props.employer.name}</h3>
                    <h3 className="employer-dates">{this.props.employer.dates}</h3>
                </div>
                <ul className="job-functions">
                    { this.renderJobFunctions() }
                </ul>
            </div>
        );
    }
}

export default Employment;