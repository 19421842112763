import React from 'react';

import { Link } from 'react-router-dom';
import AccessibilityMenu from '../../accessibility-tools/accessibility-menu';
import MenuToggle from './menu-toggle/menu-toggle';

class HeaderMenu extends React.Component {

    renderMenu() {
        if(!this.props.mobile) { //Desktop view
            return(<div className="wide-view">{this.renderMenuOptions()}</div>);
        } else {
            return(
                <div className={this.defineMenuClasses()}>
                    {this.renderMenuOptions()}
                    <AccessibilityMenu updatecurrentfont={this.props.updatecurrentfont}
                        currentfont={this.props.currentfont} />
                </div>
            );
        }
    }

    renderMenuOptions() {
        var menuJsx = [];
        if(this.props.options !== null) {

            this.props.options.forEach(option => {
                menuJsx.push(<Link to={option.path} key={"header-item-"+option.id} className="header-link" tabIndex={option.tabIndex}>{option.name}</Link>);
            });
        }
        return menuJsx;
    }

    renderMenuToggle() {
        if(this.props.mobile) {
            return <MenuToggle id="menu-toggle-switch" menucollapsed={this.props.menucollapsed} menutogglefunction={this.props.menutogglefunction} />
        }
    }

    defineMenuClasses() {
        var classes = "mobile-view ";
        if(!this.props.menucollapsed) {
            classes = classes + "expanded";
        }
        return classes;
    }

    render() {
        return(
            <div className="menu-container">
                {this.renderMenuToggle()}
                {this.renderMenu()}
            </div>
        );
    }
}

export default HeaderMenu;