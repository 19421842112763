import React from 'react';

import HeaderMenu from './header-menu/header-menu';
import SectionConfig from '../../resources/configuration/section-config.json';

class Header extends React.Component {
    
    render() {
        return (
            <nav className="container-fluid navbar-container">
                <h1 className="title-text"><span className="title-first">TOM</span><span className="title-second">POTTER</span></h1>
                <HeaderMenu mobile={this.props.mobile} 
                    menucollapsed={this.props.menucollapsed} 
                    menutogglefunction={this.props.menutogglefunction}
                    updatecurrentfont={this.props.updatecurrentfont} 
                    options={SectionConfig.sections}
                    currentfont={this.props.currentfont} />
            </nav>
        );
    }
}

export default Header;