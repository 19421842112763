import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './components/header/header';
import Overlay from './components/overlay/overlay';
import Home from './sections/home';
import Projects from './sections/projects';
import AccessibilityMenu from './components/accessibility-tools/accessibility-menu';
import Resume from './sections/resume';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewIsMobile: window.matchMedia('(max-width: 768px)').matches,
      overlayIsVisible: false,
      menuIsCollapsed: true,
      currentFont: "default"
    }

    this.setOverlayVisibility = this.setOverlayVisibility.bind(this);
    this.determineIfViewIsMobile = this.determineIfViewIsMobile.bind(this);
    this.updateMenuState = this.updateMenuState.bind(this);
    this.updateCurrentFont = this.updateCurrentFont.bind(this);
  }

  setOverlayVisibility(overlayIsVisible) {
    this.setState({
      overlayIsVisible: overlayIsVisible
    });
  }

  determineIfViewIsMobile() {
    this.setState({
      viewIsMobile: window.matchMedia('(max-width: 768px)').matches
    });
  }

  renderOverlay() {
    if(this.state.overlayIsVisible) {
      return <Overlay />
    }
  }

  updateMenuState(menuIsCollapsed) {
    this.setState({
      menuIsCollapsed: menuIsCollapsed
    });
  }

  updateCurrentFont(currentFont) {
    
    this.setState({
      currentFont: currentFont
    });
  }

  renderAccessibilityMenu() {
    if(!this.state.viewIsMobile) { //If mobile view then accessibility menu will render in slide menu
      return (
        <AccessibilityMenu updatecurrentfont={this.updateCurrentFont}
                        currentfont={this.state.currentFont} />
      );
    }
  }
  
  applyAccessibilityParameters() {
    var classes = this.state.currentFont;  //Keeping function and variable as it may be used for alt color schemes later
    return classes;
  }
  
  componentDidMount() {
    this.determineIfViewIsMobile();
    window.onresize = this.determineIfViewIsMobile;
  }

  render() {
    return (
      <Router>
        <div id="body-container" className={this.applyAccessibilityParameters()}>
          <Header mobile={this.state.viewIsMobile} 
            menucollapsed={this.state.menuIsCollapsed} 
            menutogglefunction={this.updateMenuState} 
            updatecurrentfont={this.updateCurrentFont} 
            currentfont={this.state.currentFont} />
          {this.renderOverlay()}
          <div className="content-container container-fluid">
            <Switch>
                <Route path="/resume">
                  <Resume />
                </Route>
                <Route path="/projects">
                  <Projects />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
            </Switch>
          </div>
          {this.renderAccessibilityMenu()}
        </div>
      </Router>
    );
  }
}

export default App;