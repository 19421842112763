import React from 'react';

import ContentReference from '../resources/configuration/text-config.json';
import ProfileImage from '../resources/img/profile1.jpg';

//
class Home extends React.Component {

    render() {
        return(
            <div className="container">
                <div className="content-flex">
                    <p id="statement">
                        {ContentReference.home.introduction}

                        <br />
                        <br />
                        <a href="mailto:tom@tompotter.dev"><i className="material-icons email-icon">email</i> TOM@tompotter.dev</a>
                    </p>

                    <img id="profile-image" src={ProfileImage} alt={ContentReference.home.profileImgAlt} />
                </div>
            </div>
        );
        }
}


//{ContentReference.home.profileImage}
export default Home;