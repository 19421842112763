import React from 'react';

class Education extends React.Component {

    constructor(props) {
        super(props);
        this.renderDegrees = this.renderDegrees.bind(this);
    }

    renderDegrees() {
        var degreeJsx = [];
        var index = 0;
        this.props.degrees.forEach(degree => {
            degreeJsx.push(
                <p key={"degree"+index} className="degree-listing" >{degree.type + " - " + degree.year}</p>
            );
            index++;
        });

        return degreeJsx;
    }

    render() {
        return (
            <div className="edu-container">
                <p className="school-name">{this.props.institution}</p>
                <ul className="edu-ul">
                    {this.renderDegrees()}
                </ul>
            </div>
        );
    }
}

export default Education;